import React from "react";
import { graphql, Link } from "gatsby";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Banner from "../components/Banner/Banner";
import StatsListingModule from "../components/StatsListing/StatsListing";
import TileBlock from "../components/TileBlock/TileBlock";
import DetailMap from "../components/map/detail-map";
import AreaguidePropertiesCarousel from "../components/AreaguidePropertiesCarousel/AreaguidePropertiesCarousel";
import SEO from "../components/Seo/seo";
import { Container } from "react-bootstrap";
import { AreaguideBaseURL } from "../components/common/site/utils";
import Breadcrumb from "../components/Breadcrumb/site-breadcrumbs";
import BookValuationModule from "../components/BookValuationModule/BookValuationModule";

const AreaguideDetailPage = ({ data }) => {
  const areaguide = data.glstrapi.areaguide;
  const metadesc =
    "Know more about " +
    areaguide.Name +
    " here. Contact one of our estate agents for assistance in finding the right property for you in " +
    areaguide.Name +
    ".";

  const Breadcrumbs = (
    <Breadcrumb
      style="transparent"
      alias="areas-we-cover"
      menulabel={areaguide?.Name}
      className={"transparent-bg"}
    />
  );
  return (
    <div className="areaguide-detail-page">
      <SEO
        title={`${areaguide?.Name} area guide`}
        description={metadesc}
      />
      <Header />
      <div className="d-none d-md-block d-lg-none">
        <Breadcrumb
          style="default-breadcrumbs"
          alias="areas-we-cover"
          menulabel={areaguide?.Name}
        />
      </div>
      <Container className="d-md-none d-flex align-items-center areaguide-back-arrow">
        <Link to={AreaguideBaseURL} className="d-flex align-items-center">
          <i className="icon icon-back-arrow"></i>
          <span>Back to Area Guides</span>
        </Link>
      </Container>
      <Banner
        id={areaguide.id}
        title={areaguide.Name}
        metattitle={areaguide.Meta_Title}
        image={areaguide.Banner_Image}
        imagetransforms={areaguide.imagetransforms}
        bannertitle={`Living in ${areaguide.Name}`}
        description={`<p>${areaguide.Description}</p>`}
        cta={areaguide.Banner_CTA}
        reviews={areaguide.Banner_Reviews}
        Breadcrumbs={Breadcrumbs}
      />

      {areaguide.Modules.map((module) => (
        <>
          {module.__typename === "GLSTRAPI_ComponentComponentsStatsListing" && (
            <StatsListingModule module={module} />
          )}
          {module.__typename === "GLSTRAPI_ComponentComponentsAreaguideTileBlock" && (
            <TileBlock
              title={module.Title}
              content={module.Content}
              image={module.Image}
              imgPosition={module.Image_Position}
              videoLink={module.Video_Link}
            />
          )}
        </>
      ))}
      <AreaguidePropertiesCarousel area={areaguide.Name} areaUrl={areaguide.URL} />
      {areaguide.Latitude && areaguide.Longitude && (
        <DetailMap Latitude={areaguide.Latitude} Longitude={areaguide.Longitude} />
      )}
      <BookValuationModule />
      <Footer popularsearch="pagedetails" area={areaguide?.Name}/>
    </div>
  );
};

export default AreaguideDetailPage;

export const pageQuery = graphql`
  query GetAreaguidePage($articleId: ID!) {
    glstrapi {
      areaguide(id: $articleId, publicationState: LIVE) {
        Banner_CTA {
          id
          CTA_Label
          CTA_Secondary_Link
          CTA_Link {
            Label
            Primary_URL
            Secondary_URL
            id
          }
        }
        Banner_Image {
          url
        }
        Banner_Reviews
        Description
        Meta_Description
        Meta_Title
        Modules {
          ... on GLSTRAPI_ComponentComponentsStatsListing {
            __typename
            id
            Stats_Description
            Stats_Heading
            Stats_List {
              Stats_Caption
              Stats_Icon_Class
              Stats_Link {
                Label
                Primary_URL
                Secondary_URL
              }
              Stats_Link_Label
              Stats_Prefix
              Stats_Value
              id
            }
          }
          ... on GLSTRAPI_ComponentComponentsAreaguideTileBlock {
            __typename
            id
            Content
            Image_Position
            Title
            Video_Link
            Image {
              alternativeText
              url
              url_sharp {
                id
                childImageSharp {
                  gatsbyImageData(
                    formats: WEBP
                    width: 688
                    layout: FIXED
                    quality: 90
                    transformOptions: { cropFocus: CENTER, fit: COVER }
                  )
                }
              }
            }
          }
        }
        Name
        URL
        id
        imagetransforms
        Latitude
        Longitude
      }
    }
  }
`;
